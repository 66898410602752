
import { 
  getSaleList,
  getMatkl,
  getproductSeach,
  hasLockPrice,
  hasUnLockPriceBy,
  getCustCisCode,
  savePrice,
  saveAndPublish,
  checkValid,
  getEditInfo,
  updateAndPublish,
  updatePrice

} from './api'
import moment from 'moment';
export default {
  components: {
  },
  name: "",
  data() {
    return {
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/channelWholesalePrice/businessChannelPriceList",
          name: "businessChannelPriceList",
          title: "渠道价格列表"
        },
        {
          path: "/channelWholesalePrice/businessChannelPriceadd",
          name: "addOredit",
          title: "渠道价格新增"
        }
      ],
      pageLoadFlag: false,
      orgList:[], // 组织数据
      orgCode:'', //组织code
      MatklList:[],//物料组
      MatklCode:'',// 物料组code
      timeStart:'', // 开始时间
      endTime:'', // 结束时间
      productList:[], // 产品型号
      proctText:'', // 输入的检索条件
      qudaoPrice:'',// 渠道批发价
      ratailPrice:'',//零售价
      zhidaoprice:'', // 海信是否有指导价
      samePrice:'',// 海信是否有统一价
      FXteshuPrice:'',//分销特殊价
      SjText:'',// 商家模糊搜索数据
      sjList:[] , // 商家数据
      isLoading:false,
      disabledButton:false,
      showBussiness:true,
      orgName:'',
      MatklName:'',
      id:'',
      fetching: false,
      buinessFe:false
    };
  },
  watch: {
     
  },

  mounted() {
    
    if(this.$route.query && this.$route.query.type == 'edit') {
      this.getInfo(this.$route.query.id)
      this.id = this.$route.query.id
      this.showBussiness = false
    } else {
      this.getAfentOrg()
      this.showBussiness = true
    }
  },
  
  methods:{
    /* 以下是编辑代码 */
    getInfo(id) {
      let data = {
        id:id
      }
      getEditInfo(data).then(res => {
        if(res.data && res.data.code == 0) {
          this.orgCode = res.data.data.orgName
          this.orgName = res.data.data.orgId // 组织id
          this.MatklCode = res.data.data.matklName
          this.MatklName = res.data.data.matklId // 物料组id
          this.timeStart = res.data.data.startDate // 开始时间
          this.endTime = res.data.data.endDate // 结束时间
          this.proctText = res.data.data.productModelName
          //  编辑的时候商家编码不显示
          this.showBussiness = false
          this.qudaoPrice = res.data.data.channelPrice // 渠道批发价格
          this.ratailPrice = res.data.data.retailPrice // 零售价格
          this.FXteshuPrice = res.data.data.fxPrice // 分销商价格
          this.hasLockPriceedit()
          this.hasUnLockPriceByedit()
        } else {
          this.$message.warning('获取详情失败',res.data.msg)
        }
      })
    },
    // 是否有海信统一价 
    hasLockPriceedit() {
      let data  = {
        productName:this.proctText,
        orgId:this.orgName,
        start:this.timeStart,
        end:this.endTime
      }
      hasLockPrice(data).then(res=>{
        if(res && res.data.length>0){
          this.samePrice = 'Y'
        } else {
          this.samePrice = 'N'
        }
      })
    },
    // 查询是否有海信指导价
    hasUnLockPriceByedit(){
      let data  = {
        productName:this.proctText,
        orgId:this.orgName,
        start:this.timeStart,
        end:this.endTime
      }
      hasUnLockPriceBy(data).then(res=>{
        if(res && res.data.length>0){
          this.zhidaoprice = 'Y'
        } else {
          this.zhidaoprice = 'N'
        }
      })
    },
    // 修改
    changePrice() {
      this.pageLoadFlag = true
      let data = {
        id:  this.id ,
        productName: this.proctText,
        startDate:  this.timeStart,
        endDate:this.endTime,
        channelPrice: this.qudaoPrice,
        fxPrice: this.FXteshuPrice,
        retailPrice: this.ratailPrice,
      }
      updatePrice(data).then(res =>{
        if(res.data.code == 0){
          this.$message.success('保存修改成功')
          this.pageLoadFlag = false
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            this.goback()
          },2000);
        }
      })
    },
    // 修改并发布
    updateAndPublish(){
      this.pageLoadFlag = true
      let data = {
        id:  this.id ,
        productName: this.proctText,
        startDate:  this.timeStart,
        endDate:this.endTime,
        channelPrice: this.qudaoPrice,
        fxPrice: this.FXteshuPrice,
        retailPrice: this.ratailPrice,
      }
      updateAndPublish(data).then(res=>{
        if(res.data.code == 0){
          this.$message.success('保存并发布成功')
          this.pageLoadFlag = false
          // 关闭窗口之前刷新父页面
          window.opener.location.reload();
          this.timer = setTimeout(()=>{  //设置延迟执行
            this.goback()
          },2000);
        }
      })
    },
    /* 编辑代码结束 */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 返回
    goback(){
      window.close()
      this.$router.push({ path:'/channelWholesalePrice/businessChannelPriceList'});
    },
    
    /* 以下是新增代码 */
    // 获取销售组织数据
    getAfentOrg(){
      let data = {}
      getSaleList(data).then(res=>{
        this.orgList = res.data.list
      })
    },
    // 更改销售组织查询物料组数据
    changeOrg(){
      let data = {
        orgId :this.orgCode
      }
      getMatkl(data).then(res=>{
        this.MatklList = res.data.list
      })
    },
    // 更改物料组织的时候清空产品列表
    changeMartl(){
      this.proctText = ''
      this.productList = []
      this.SjText = ''
    },
    // 更改开始时间
    handleTimeChange(e, defaultTime){
      this.timeStart = defaultTime;
      if(this.$route.query.type == 'edit') {
          if(this.endTime !== '') {
            if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
             
              this.hasLockPriceedit()
              this.hasUnLockPriceByedit()
            
            } else if (this.orgCode && this.MatklCode && this.proctText  && this.timeStart && this.endTime) {
              this.hasLockPriceedit()
              this.hasUnLockPriceByedit()
            }
          }
      } else {
       
        if(this.endTime !== '') {
          if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
           
            this.getcheckValid()
            this.hasLockPrice() 
            this.hasUnLockPriceBy()
          } else if (this.orgCode && this.MatklCode && this.proctText  && this.timeStart && this.endTime) {
            this.getcheckValid()
            this.hasLockPrice() 
          }
        }
      }
      
    },
    // 开始时间选择不能选择今天之前的日期
    disabledDate(current) {
      return current && current < moment().startOf('day');
    },
    // 更改结束时间
    handleendTimeChange(e, defaultTime){
      this.endTime = defaultTime;
      if(this.$route.query.type == 'edit') {
          if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
            this.hasLockPriceedit()
            this.hasUnLockPriceByedit()
            this.getcheckValid()
          } else if (this.orgCode && this.MatklCode && this.proctText  && this.timeStart && this.endTime) {
            this.hasLockPriceedit()
            this.hasUnLockPriceByedit()
          }
      } else {
        if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
          this.getcheckValid()
          this.hasLockPrice() 
          this.hasUnLockPriceBy()
        } else if (this.orgCode && this.MatklCode && this.proctText  && this.timeStart && this.endTime) {
          this.hasLockPrice() 
          this.hasUnLockPriceBy()
        }
      }
      
    },
    // 产品型号搜索
    changeProotext(){
      if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
        this.getcheckValid()
      }
    },
 
     // 输入模糊搜索代理商
    getProductList(value) {
      this.fetching = true
      this.proctText = value
      let data = {
        term:this.proctText,
        matklId:this.MatklCode
      }
      getproductSeach(data).then(res => {
        let data;
        data = res.data;
        this.productList = [...new Set(data)]; //缓存列表数据，前端分页
        this.fetching = false
      })
    },

    search(){
      // this.getSJList()
      if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
        debugger
        this.getcheckValid()
      } else if (this.orgCode && this.MatklCode && this.proctText  && this.timeStart && this.endTime) {
        this.hasLockPrice() 
        this.hasUnLockPriceBy()
      }
    },
    // 获取商家编码
    getSJList(value){
      this.buinessFe = true
      this.SjText = value
      let data = {
        term:this.SjText,
        matklId:this.MatklCode,
        orgId:this.orgCode,

      }
      getCustCisCode(data).then(res => {
        let data;
        data = res.data;
        this.sjList = [...new Set(data.list)]; //缓存列表数据，前端分页
        this.buinessFe = false
      })
    },
    // 检查是都有海信指导价和统一价
    cherkPrice(){
      // this.hasLockPrice() 
      // this.hasUnLockPriceBy()
      // this.getcheckValid()
      if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
        debugger
        this.getcheckValid()
      }
    },
    // 是否有海信统一价 
    hasLockPrice() {
      let data  = {
        productName:this.proctText,
        orgId:this.orgCode,
        start:this.timeStart,
        end:this.endTime
      }
      hasLockPrice(data).then(res=>{
        if(res && res.data.length>0){
          this.samePrice = 'Y'
        } else {
          this.samePrice = 'N'
        }
      })
    },
    // 查询是否有海信指导价
    hasUnLockPriceBy(){
      let data  = {
        productName:this.proctText,
        orgId:this.orgCode,
        start:this.timeStart,
        end:this.endTime
      }
      hasUnLockPriceBy(data).then(res=>{
        if(res && res.data.length>0){
          this.zhidaoprice = 'Y'
        } else {
          this.zhidaoprice = 'N'
        }
      })
    },
    // 更改搜索条件查询
    changeSjtext(){
      
      if(this.orgCode && this.MatklCode && this.proctText && this.SjText && this.timeStart && this.endTime) {
        debugger
        this.getcheckValid()
      }
    },
    // 新增查询渠道批发价
    getcheckValid() {
      let data  = {
        productName:this.proctText,
        orgId:this.orgCode,
        matklId:this.MatklCode,
        start:this.timeStart,
        end:this.endTime,
        cisCode:this.SjText,
        type: 'add'
      }
      let editData = {
        productName:this.proctText,
        orgId:this.orgName,
        matklId:this.MatklName,
        start:this.timeStart,
        end:this.endTime,
       
      }
      let finallData = this.$route.query == 'edit'?editData:data
      checkValid(finallData).then(res=>{
        if(res.data.code == 0) {
          if(res.data && res.data.data) {
            this.qudaoPrice = res.data.data.channelPrice
            this.ratailPrice = res.data.data.retailPrice
            this.disabledButton = false
          } else {
            this.qudaoPrice = ''
            this.ratailPrice = ''
            this.disabledButton = false
          }
        } else {
          this.qudaoPrice = ''
          this.ratailPrice = ''
          // this.endTime = ''
          // this.timeStart = ''
          this.disabledButton = true
          this.$message.warning(res.data.msg)
        }
      })
    },
  //  存Draft为草稿
    saveDraft(type) {
      if(!this.orgCode) {
        this.$message.warning('请选择销售组织')
        return
      }
      if(!this.MatklCode) {
        this.$message.warning('请选择物料组')
        return
      }
      if(!this.timeStart) {
        this.$message.warning('请选择开始时间')
        return
      }
      if(!this.proctText) {
        this.$message.warning('请选择产品型号')
        return
      }
      if(!this.SjText) {
        this.$message.warning('请选择商家编码')
        return
      }
      if(!this.ratailPrice) {
        this.$message.warning('请填写零售价')
        return
      }
      this.pageLoadFlag = true
      let data = {
        orgId:this.orgCode,
        cisCode:this.SjText,
        productName:this.proctText,
        startDate:this.timeStart,
        endDate:this.endTime,
        fxPrice:this.FXteshuPrice,
        retailPrice:this.ratailPrice,
        channelPrice:this.qudaoPrice,
        errMessage1:this.samePrice,
        errMessage2:this.zhidaoprice
      }
      if(type == 'draft'){
        savePrice(data).then(res => {
          if(res.data.code == 0) {
            this.$message.success('保存草稿成功')
            this.pageLoadFlag = false
            // 关闭窗口之前刷新父页面
            window.opener.location.reload();
            this.timer = setTimeout(()=>{  //设置延迟执行
              this.goback()
            },2000);
          } else {
            this.$message.warning('保存失败',res.data.msg)
            this.pageLoadFlag = false
          }
        })
      }
      if(type == 'save') {
        saveAndPublish(data).then(res => {
          if(res.data.code == 0) {
            this.$message.success('保存并发布成功')
            this.pageLoadFlag = false
            // 关闭窗口之前刷新父页面
            window.opener.location.reload();
           this.timer = setTimeout(()=>{  //设置延迟执行
             this.goback()
           },2000);
            
          } else {
            this.$message.warning('保存并失败',res.data.msg)
            this.pageLoadFlag = false
          }
        })
      }

    },
    /* 新增结束 */
  }
  
};